$(document).ready(function () {
	$('.cats li a').on('click', function (e) {
		e.preventDefault();
		var cat = [];
		$(this).toggleClass('active');
		$('.cats .active').each(function () {
			cat.push($(this).data("cat"));
		});
		//console.log(cat);
		$('.grid article').removeClass('hidden');
		if (cat.length >= 1) {
			$('.grid article').each(function () {
				//console.log(cat, $(this).data('cat'))
				//console.log($(this));
				var check = false;
				for (var i = 0; i < cat.length; i++) {
					if ($(this).data('cat').indexOf(cat[i]) >= 0) {
						check = true;
					}
				}
				if (check == false) {
					$(this).addClass('hidden');
				}
				//console.log(check);
			})
		}
	});
});
