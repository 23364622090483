$( document ).ready(function() {
	$.mobile.ajaxEnabled=false;

	$(function() {
		$('aside .toggler').click(function (e) {
			e.preventDefault();
			$(this).closest('aside').toggleClass('open');
		});

		$('aside').on('swiperight', function (e) {
			$(this).addClass('open');
		});

		$('aside').on('swipeleft', function (e) {
			$(this).removeClass('open');
		});
	});
});
